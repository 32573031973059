import { IncomeTypes, useTenantSettings } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type Income = typeof IncomeTypes;

type IncomeValue = Income[keyof Income];

type IncomeMap = Record<
    IncomeValue,
    {
        id: IncomeValue;
        labelKey: string;
    }
>;

export const INCOME_MAP: IncomeMap = {
    [IncomeTypes.BONUS]: {
        id: IncomeTypes.BONUS,
        labelKey: 'income.bonus',
    },
    [IncomeTypes.COMMISSIONS]: {
        id: IncomeTypes.COMMISSIONS,
        labelKey: 'income.commission',
    },
    [IncomeTypes.HOURLY]: {
        id: IncomeTypes.HOURLY,
        labelKey: 'income.hourly',
    },
    [IncomeTypes.HOURLY_COMMISSIONS]: {
        id: IncomeTypes.HOURLY_COMMISSIONS,
        labelKey: 'income.howrlyCommision',
    },
    [IncomeTypes.NONE]: {
        id: IncomeTypes.NONE,
        labelKey: 'income.none',
    },
    [IncomeTypes.ON_CONTRACT]: {
        id: IncomeTypes.ON_CONTRACT,
        labelKey: 'income.onContract',
    },
    [IncomeTypes.OTHER]: {
        id: IncomeTypes.OTHER,
        labelKey: 'Other',
    },
    [IncomeTypes.OVERTIME_AND_PREMIUMS]: {
        id: IncomeTypes.OVERTIME_AND_PREMIUMS,
        labelKey: 'income.overtimePremiums',
    },
    [IncomeTypes.PENSION]: {
        id: IncomeTypes.PENSION,
        labelKey: 'income.pension',
    },
    [IncomeTypes.SALARIED]: {
        id: IncomeTypes.SALARIED,
        labelKey: 'income.salaried',
    },
    [IncomeTypes.SEASONAL]: {
        id: IncomeTypes.SEASONAL,
        labelKey: 'income.seasonal',
    },
    [IncomeTypes.SELF_EMPLOYED]: {
        id: IncomeTypes.SELF_EMPLOYED,
        labelKey: 'income.selfEmployed',
    },
    [IncomeTypes.SELF_EMPLOYED_STATED]: {
        id: IncomeTypes.SELF_EMPLOYED_STATED,
        labelKey: 'income.selfEmployedStated',
    },
};

export const useIncomeOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { incomeTypes },
        },
    } = useTenantSettings();

    return incomeTypes.map((typeId) => ({
        value: typeId,
        label: i18n._(INCOME_MAP[typeId]?.labelKey || ''),
    }));
};
