import { useTenantSetting } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

export const BRIDGE_LOAN_TYPE = {
    SECURED: 'SECURED',
    UNSECURED: 'UNSECURED',
} as const;

type BridgeLoan = typeof BRIDGE_LOAN_TYPE;

type BridgeLoanValue = BridgeLoan[keyof BridgeLoan];

type BridgeLoanMap = Record<
    BridgeLoanValue,
    {
        id: BridgeLoanValue;
        labelKey: string;
    }
>;

export const BRIDGE_LOAN_TYPE_MAP: BridgeLoanMap = {
    [BRIDGE_LOAN_TYPE.SECURED]: {
        id: BRIDGE_LOAN_TYPE.SECURED,
        labelKey: 'bridgeLoanType.secured',
    },
    [BRIDGE_LOAN_TYPE.UNSECURED]: {
        id: BRIDGE_LOAN_TYPE.UNSECURED,
        labelKey: 'bridgeLoanType.unsecured',
    },
};

const CMLS_BRIDGE_LOAN_TYPE_OPTIONS = [
    BRIDGE_LOAN_TYPE_MAP[BRIDGE_LOAN_TYPE.SECURED],
    BRIDGE_LOAN_TYPE_MAP[BRIDGE_LOAN_TYPE.UNSECURED],
];

export const useBridgeLoanTypeOptions = () => {
    const { i18n } = useI18n();

    const {
        settings: { enableBridgeLoanTypes },
    } = useTenantSetting('enableBridgeLoan');

    if (!enableBridgeLoanTypes) {
        return [];
    }

    return CMLS_BRIDGE_LOAN_TYPE_OPTIONS.map(({ id, labelKey }) => ({
        value: id,
        label: i18n._(labelKey),
    }));
};
