import { getAddressSchema } from './address';
import {
    getAmountFrequencyRequiredSchema,
    getAmountFrequencySchema,
} from './amount-frequency';
import { moneyRequired } from './number';
import {
    getOtherPropertyCondoFeesSchema,
    getOtherPropertyRentalIncomeRatioSchema,
    getOtherPropertyRentalIncomeSchema,
    getOtherPropertyTaxesSchema,
    getOtherPropertyMortgageSchema,
    isSold,
    stringRequiredIfBridgeLoanInfoEnabledAndSold,
    stringRequiredIfBridgeLoanRequiredAndBridgeLoanTypeEnabled,
} from './other-property-partial';
import { stringRequired } from './string';
import yup from './yup-extended';

import type { I18n } from '@lingui/core';
import type { PurposeAfterTransaction } from 'types/property';

export const getOtherPropertyModelSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: stringRequired(i18n),
            type: stringRequiredIfNotSold(i18n),
        })
        .concat(getOtherPropertySchema(i18n));

export const getOtherPropertySchema = (i18n: I18n) =>
    yup.object().shape({
        address: getAddressSchema(i18n),
        currentPurpose: stringRequired(i18n),
        afterTransactionPurpose: stringRequired(i18n),
        mortgage: getOtherPropertyMortgageSchema(i18n),

        // IS SOLD
        currentSaleStatus: stringRequiredIfSold(i18n),
        purchasePrice: moneyRequiredIfSold(i18n),
        sellingDate: dateRequiredIfSold(i18n),

        // NOT SOLD CLIENT KEEP this other / owned property
        estimatedPropertyValue: moneyRequiredIfKeep(i18n),
        type: yup.string().emptyAsUndefined().nullable(),
        taxes: getOtherPropertyTaxesSchema(i18n),
        rentalIncome: getOtherPropertyRentalIncomeSchema(i18n),
        rentalIncomeRatio: getOtherPropertyRentalIncomeRatioSchema(i18n),
        condoFees: getOtherPropertyCondoFeesSchema(i18n),
        heatingCost: yup.object().when(['type', 'condoFees'], {
            is: (type, condoFees) =>
                (type === 'CONDO' && condoFees?.heatingIncluded === false) ||
                condoFees?.heatingIncluded === 'false',
            then: getAmountFrequencyRequiredSchema(i18n),
            otherwise: getAmountFrequencySchema(i18n),
        }),

        // BRIDGE LOAN
        bridgeLoanRequired: stringRequiredIfBridgeLoanInfoEnabledAndSold(i18n),
        bridgeLoanAmount: yup.number().when('bridgeLoanRequired', {
            is: (bridgeLoanRequired: 'true' | 'false') =>
                bridgeLoanRequired === 'true',
            then: moneyRequired(i18n).when(
                ['purchasePrice', 'mortgage.balance'],
                // @ts-ignore
                (purchasePrice: number, mortgageBalance: number, schema) =>
                    schema.test({
                        test: (bridgeLoanAmount: number) =>
                            bridgeLoanAmount <=
                            0.95 * (purchasePrice - mortgageBalance),
                        message: i18n._({ id: 'bridgeLoanValidation.error' }),
                    })
            ),
            otherwise: yup.number().emptyAsUndefined().nullable(),
        }),
        bridgeLoanRate: yup.number().emptyAsUndefined().nullable(),
        bridgeLoanType:
            stringRequiredIfBridgeLoanRequiredAndBridgeLoanTypeEnabled(i18n),
    });

export const stringRequiredIfSold = (i18n: I18n) =>
    yup.string().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            isSold(afterTransactionPurpose),
        then: stringRequired(i18n),
        otherwise: yup.string().nullable(),
    });

export const dateRequiredIfSold = (i18n: I18n) =>
    yup.date().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            isSold(afterTransactionPurpose),
        then: yup
            .date()
            .nullable()
            .emptyAsUndefined()
            .fixBackendIssue0001()
            .required(i18n._({ id: 'error.fieldRequired' }))
            .min(new Date(), i18n._({ id: 'error.dateShouldBeFuture' })),
        otherwise: yup
            .date()
            .emptyAsUndefined()
            .fixBackendIssue0001()
            .nullable()
            .optional(),
    });

export const stringRequiredIfNotSold = (i18n: I18n) =>
    yup.string().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose: PurposeAfterTransaction) =>
            isSold(afterTransactionPurpose),
        then: stringRequired(i18n),
        otherwise: yup.string().nullable(),
    });

export const stringRequiredIfKeep = (i18n: I18n) =>
    yup.string().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose) => afterTransactionPurpose !== 'SOLD',
        then: stringRequired(i18n),
        otherwise: yup.string().nullable(),
    });

export const moneyRequiredIfSold = (i18n: I18n) =>
    yup.number().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose) => afterTransactionPurpose === 'SOLD',
        then: moneyRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .emptyAsUndefined(),
        otherwise: yup.number().emptyAsUndefined().nullable(),
    });

export const moneyRequiredIfKeep = (i18n: I18n) =>
    yup.number().when('afterTransactionPurpose', {
        is: (afterTransactionPurpose) => afterTransactionPurpose !== 'SOLD',
        then: moneyRequired(i18n)
            .min(1, i18n._({ id: 'error.fieldRequired' }))
            .emptyAsUndefined(),
        otherwise: yup.number().emptyAsUndefined().nullable(),
    });
