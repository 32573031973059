import React from 'react';

import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { EditableCell } from 'components/editable';
import styles from 'components/employment/section/section.module.scss';
import { useIncomeOptions } from 'constants/icnome';
import { useI18n } from 'providers/i18n/use-i18n';
import { compareForId } from 'utils/validations/comparators';

import { EmploymentTypeSectionProps } from './employment-type';

export const NoIncome = ({
    isCreating,
    isEditing,
    employment,
    partialSchema,
}: EmploymentTypeSectionProps) => {
    const { i18n } = useI18n();

    const { watch } = useFormContext() || {};

    const { employedMonths, employedYears, incomeType, isCurrent } = employment;

    let employedMonthsWatch = employedMonths;

    let employedYearsWatch = employedYears;

    if (watch) {
        employedMonthsWatch = watch('employedMonths');

        employedYearsWatch = watch('employedYears');
    }
    const incomeOptions = useIncomeOptions();

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('noIncomeDetails')}
            </Typography>
            <Grid
                className={cn(styles.grid, {
                    [styles['grid--auto-fit']]: isCreating,
                })}
                gap={4}
            >
                {!isCreating && (
                    <EditableCell
                        isEditing={isEditing}
                        name="incomeType"
                        label="Income Type"
                        fieldType="readonly"
                        options={incomeOptions}
                        value={incomeType}
                        isFieldInvalidCompareFn={compareForId(employment)}
                    />
                )}
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="isCurrent"
                    label="current"
                    fieldType="boolean"
                    value={isCurrent}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !employedMonthsWatch &&
                        employedMonthsWatch !== 0
                    }
                    isEditing={isEditing}
                    name="employedYears"
                    label="employedYears"
                    disabled={
                        !!employedMonthsWatch || employedMonthsWatch === 0
                    }
                    fieldType="number"
                    integer
                    value={employedYears}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required={
                        !partialSchema &&
                        !employedYearsWatch &&
                        employedYearsWatch !== 0
                    }
                    isEditing={isEditing}
                    name="employedMonths"
                    label="employedMonths"
                    disabled={!!employedYearsWatch || employedYearsWatch === 0}
                    fieldType="number"
                    integer
                    value={employedMonths}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
            </Grid>
        </Flex>
    );
};
