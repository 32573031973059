import { Flex, Grid, Typography } from '@nestoca/ui';
import cn from 'classnames';

import { EditableCell } from 'components/editable';
import { FREQUENCY } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import { useEditingRights } from 'store/rights';
import { compareForId } from 'utils/validations/comparators';

import { IncludeCheckboxWithTooltip } from '../../popper/include-checkbox-with-tooltip';
import { OnToggleIncluded } from '../employment-type';

import styles from './section.module.scss';

import type { AmountFrequency } from 'types';
import type {
    IncomeEmployment,
    IncomeIncludedFieldName,
} from 'types/applicant';

export const showFrequency = (salary: AmountFrequency) => {
    return salary?.amount ? salary.frequency : undefined;
};

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    employment: IncomeEmployment & { totalIncome?: number };
    onToggleIncluded: OnToggleIncluded;
};

export const IncomeDetailsShort = ({
    isCreating,
    isEditing,
    employment,
    onToggleIncluded,
}: Props) => {
    const { i18n } = useI18n();

    const hasEditingRights = useEditingRights();

    const { incomeOverrideIncluded, isCurrent, salary } = employment;

    const handleToggleIncluded = (
        checked: boolean,
        fieldName: IncomeIncludedFieldName
    ) => {
        onToggleIncluded?.(checked, fieldName);
    };

    return (
        <Flex
            className={cn(styles.section, {
                [styles['section--modal']]: isCreating,
            })}
            direction="column"
            gap={4}
        >
            <Typography weight={7} textColor="var(--color-black)">
                {i18n._('incomeDetails')}
            </Typography>
            <Grid
                className={
                    isEditing ? styles['editing-incomes'] : styles.incomes
                }
                gap={4}
            >
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.amount"
                    label="income"
                    fieldType="money"
                    value={salary?.base?.amount}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="salary.base.frequency"
                    label="frequency"
                    fieldType="select"
                    options={FREQUENCY(i18n)}
                    value={showFrequency(salary?.base)}
                    isFieldInvalidCompareFn={compareForId(employment)}
                />
                {!isCreating && !isEditing && hasEditingRights && (
                    <IncludeCheckboxWithTooltip
                        shouldShowTooltip={!isCurrent && !!salary?.base?.amount}
                        handleToggleIncluded={handleToggleIncluded}
                        checked={salary?.baseRatiosIncluded && isCurrent}
                        disabled={
                            !salary?.base?.amount ||
                            incomeOverrideIncluded ||
                            !isCurrent
                        }
                        fieldName="baseRatiosIncluded"
                    />
                )}
            </Grid>
        </Flex>
    );
};
