import * as yup from 'yup';

import { MAX_MONTHS, MAX_YEARS } from 'constants/appConstants';

import {
    getAmountFrequencySchema,
    getAmountFrequencyRequiredSchema,
} from './amount-frequency';
import { booleanRequired } from './boolean';
import { numberRequired } from './number';
import { stringRequired } from './string';

import type { I18n } from '@lingui/core';
import type { EmploymentIncomeType } from 'types/applicant';

export const getCreateEmploymentPartialSchema = (i18n: I18n) =>
    yup
        .object()
        .shape({
            applicantId: numberRequired(i18n).emptyAsUndefined(),
        })
        .concat(getEmploymentPartialSchema(i18n));

export const getEmploymentTenureSchema = (i18n: I18n) =>
    yup.string().when('incomeType', {
        is: (incomeType: EmploymentIncomeType) =>
            [
                'SALARIED',
                'HOURLY',
                'HOURLY_COMMISSIONS',
                'COMMISSIONS',
                'ON_CONTRACT',
                'BONUS',
                'OVERTIME_AND_PREMIUMS',
                'SEASONAL',
                'OTHER',
            ].includes(incomeType),
        then: (schema) =>
            schema.required(i18n._({ id: 'error.fieldRequired' })),
        otherwise: (schema) => schema.optional().nullable(),
    });

export const getEmploymentEmploymentTypeSchema = (i18n: I18n) =>
    yup.string().when('incomeType', {
        is: (incomeType: EmploymentIncomeType) =>
            [
                'SALARIED',
                'HOURLY',
                'HOURLY_COMMISSIONS',
                'COMMISSIONS',
                'ON_CONTRACT',
                'BONUS',
                'OVERTIME_AND_PREMIUMS',
                'SEASONAL',
                'OTHER',
            ].includes(incomeType),
        then: (schema) =>
            schema.required(i18n._({ id: 'error.fieldRequired' })),
        otherwise: (schema) => schema.optional().nullable(),
    });

export const getEmploymentSalarySchema = (i18n: I18n) =>
    yup
        .object()
        .when('incomeType', (incomeType: EmploymentIncomeType, schema) => {
            if (
                [
                    'SALARIED',
                    'HOURLY',
                    'HOURLY_COMMISSIONS',
                    'COMMISSIONS',
                    'SELF_EMPLOYED',
                    'PENSION',
                    'ON_CONTRACT',
                    'BONUS',
                    'OVERTIME_AND_PREMIUMS',
                    'SEASONAL',
                    'OTHER',
                    'SELF_EMPLOYED_STATED',
                ].includes(incomeType)
            ) {
                return yup.object().shape({
                    base: getAmountFrequencyRequiredSchema(i18n),
                    bonus: getAmountFrequencySchema(i18n),
                    commission: getAmountFrequencySchema(i18n),
                    overtime: getAmountFrequencySchema(i18n),
                });
            }
            return schema;
        });

export const getEmploymentHasGuaranteedHoursSchema = (i18n: I18n) =>
    yup.string().when('incomeType', {
        is: (incomeType: EmploymentIncomeType) =>
            ['HOURLY', 'HOURLY_COMMISSIONS'].includes(incomeType),
        then: (schema) =>
            schema.required(i18n._({ id: 'error.fieldRequired' })),
        otherwise: (schema) => schema.optional().nullable(),
    });

export const getEmploymentSalaryPreviousYearSchema = (i18n: I18n) =>
    yup
        .object()
        .when('incomeType', (incomeType: EmploymentIncomeType, schema) => {
            if (
                [
                    'HOURLY',
                    'HOURLY_COMMISSIONS',
                    'COMMISSIONS',
                    'SELF_EMPLOYED',
                ].includes(incomeType)
            ) {
                return yup.object().shape({
                    base: getAmountFrequencyRequiredSchema(i18n),
                    bonus: getAmountFrequencySchema(i18n),
                    commission: getAmountFrequencySchema(i18n),
                    overtime: getAmountFrequencySchema(i18n),
                });
            }
            return schema;
        });

export const getEmploymentSelfEmployedSchema = (i18n: I18n) =>
    yup
        .object()
        .when('incomeType', (incomeType: EmploymentIncomeType, schema) => {
            if (['SELF_EMPLOYED'].includes(incomeType)) {
                return yup.object().shape({
                    operatingAs: stringRequired(i18n),
                    grossRevenue: getAmountFrequencyRequiredSchema(i18n),
                    grossRevenuePreviousYear:
                        getAmountFrequencyRequiredSchema(i18n),
                });
            } else if (['SELF_EMPLOYED_STATED'].includes(incomeType)) {
                return yup.object().shape({
                    operatingAs: stringRequired(i18n),
                });
            }
            return schema;
        });

export const getEmploymentPensionTypeSchema = (i18n: I18n) =>
    yup.string().when('incomeType', {
        is: (incomeType: EmploymentIncomeType) => incomeType === 'PENSION',
        then: (schema) =>
            schema.required(i18n._({ id: 'error.fieldRequired' })),
        otherwise: (schema) => schema.optional().nullable(),
    });

export const getEmploymentPartialSchema = (i18n: I18n) =>
    yup.object().shape(
        {
            incomeType: stringRequired(i18n),
            occupation: yup.string().when('incomeType', {
                is: (incomeType: string) =>
                    [
                        'SALARIED',
                        'HOURLY',
                        'HOURLY_COMMISSIONS',
                        'COMMISSIONS',
                        'SELF_EMPLOYED',
                        'SELF_EMPLOYED_STATED',
                        'ON_CONTRACT',
                        'BONUS',
                        'OVERTIME_AND_PREMIUMS',
                        'SEASONAL',
                        'OTHER',
                    ].includes(incomeType),
                then: stringRequired(i18n),
                otherwise: yup.string().nullable().optional(),
            }),
            employedYears: yup
                .number()
                .isValueNaN()
                .nullable()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueGreaterThan',
                        values: { value: 0 },
                    })
                )
                .max(MAX_YEARS, i18n._({ id: 'error.maxYears' }))
                .optional(),
            employedMonths: yup
                .number()
                .isValueNaN()
                .nullable()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueGreaterThan',
                        values: { value: 0 },
                    })
                )
                .max(MAX_MONTHS, i18n._({ id: 'error.maxMonths' }))
                .optional(),
            yearsInIndustry: yup
                .number()
                .isValueNaN()
                .optional()
                .nullable()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueGreaterThan',
                        values: { value: 0 },
                    })
                )
                .max(MAX_YEARS, i18n._({ id: 'error.maxYears' })),
            monthsInIndustry: yup
                .number()
                .isValueNaN()
                .nullable()
                .min(
                    0,
                    i18n._({
                        id: 'error.valueGreaterThan',
                        values: { value: 0 },
                    })
                )
                .max(MAX_MONTHS, i18n._({ id: 'error.maxMonths' }))
                .optional(),
            jobTitle: yup.string().optional().nullable(),
            pensionType: getEmploymentPensionTypeSchema(i18n),
            industry: yup.string().optional().nullable(),
            hasGuaranteedHours: getEmploymentHasGuaranteedHoursSchema(i18n),
            tenure: getEmploymentTenureSchema(i18n),
            employmentType: getEmploymentEmploymentTypeSchema(i18n),
            isCurrent: booleanRequired(i18n),
            salary: getEmploymentSalarySchema(i18n),
            salaryPreviousYear: getEmploymentSalaryPreviousYearSchema(i18n),
            selfEmployed: getEmploymentSelfEmployedSchema(i18n),
            incomeOverride: getAmountFrequencySchema(i18n),
            incomeOverrideIncluded: yup.boolean().nullable().default(false),
            employer: yup.object().when(
                ['incomeType', 'pensionType'],
                // @ts-ignore
                (incomeType: string, pensionType: string, schema: any) => {
                    if (
                        [
                            'SALARIED',
                            'HOURLY',
                            'HOURLY_COMMISSIONS',
                            'COMMISSIONS',
                            'SELF_EMPLOYED',
                            'SELF_EMPLOYED_STATED',
                            'ON_CONTRACT',
                            'BONUS',
                            'OVERTIME_AND_PREMIUMS',
                            'SEASONAL',
                            'OTHER',
                        ].includes(incomeType)
                    ) {
                        return yup.object().shape({
                            address: yup.object().shape({
                                unit: yup.string().optional().nullable(),
                                streetNumber: yup
                                    .string()
                                    .optional()
                                    .nullable(),
                                street: yup.string().optional().nullable(),
                                city: yup.string().optional().nullable(),
                                countryCode: yup.string().optional().nullable(),
                                stateCode: yup.string().optional().nullable(),
                                postalCode: yup.string().optional().nullable(),
                            }),
                            name: yup.string().optional().nullable(),
                            phone: yup
                                .string()
                                .optional()
                                .phone(i18n._({ id: 'error.invalidPhone' }))
                                .nullable(),
                        });
                    }

                    if (
                        incomeType === 'PENSION' &&
                        (pensionType === 'EMPLOYER' || pensionType === 'OTHER')
                    ) {
                        return yup.object().shape({
                            name: stringRequired(i18n),
                        });
                    }

                    return schema;
                }
            ),
        },
        [
            ['employedMonths', 'employedYears'],
            ['monthsInIndustry', 'yearsInIndustry'],
        ]
    );
