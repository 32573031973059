import { useTenantSettings } from '@nestoca/multi-tenant';
import { InvestorTypes } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type Investor = typeof InvestorTypes;

type InvestorValue = Investor[keyof Investor];

type InvestorMap = Record<
    InvestorValue,
    {
        id: InvestorValue;
        labelKey: string;
    }
>;

export const INVESTOR_MAP: InvestorMap = {
    [InvestorTypes.NBF8]: {
        id: InvestorTypes.NBF8,
        labelKey: 'lender.nationalBank',
    },
    [InvestorTypes['National Bank Uninsurable']]: {
        id: InvestorTypes['National Bank Uninsurable'],
        labelKey: 'lender.nationalBankUninsurable',
    },
    [InvestorTypes.TDSI3]: { id: InvestorTypes.TDSI3, labelKey: 'lender.td' },
    [InvestorTypes.LB]: {
        id: InvestorTypes.LB,
        labelKey: 'lender.laurentian',
    },
    [InvestorTypes.WH]: {
        id: InvestorTypes.WH,
        labelKey: 'lender.nestoWarehouse',
    },
    [InvestorTypes.PTC]: {
        id: InvestorTypes.PTC,
        labelKey: 'lender.peoplesTrustCompany',
    },
    [InvestorTypes.CMLS]: { id: InvestorTypes.CMLS, labelKey: 'investor.cmls' },
    [InvestorTypes.IG]: { id: InvestorTypes.IG, labelKey: 'lender.IG' },
};

export const useInvestorOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { investorTypes },
        },
    } = useTenantSettings();

    return investorTypes.map((typeId) => ({
        value: typeId,
        label: i18n._(INVESTOR_MAP[typeId].labelKey),
    }));
};
