import * as yup from 'yup';

import type { I18n } from '@lingui/core';

export const booleanRequired = (
    i18n: I18n,
    message?: string,
    values?: Record<string, any>
) =>
    yup
        .boolean()
        // on Empty value, the form will return `""` (empty string)
        .transform((value) => (value === '' ? null : value))
        // Allow null values to not trigger a `TypeError` then force it to be a boolean
        .nullable()
        .required(i18n._({ id: message || 'error.fieldRequired', values }));
