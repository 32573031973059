import { useTenantSettings } from '@nestoca/multi-tenant';
import { ApplicantResidencyStatusTypes } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type ApplicantResidencyStatus = typeof ApplicantResidencyStatusTypes;

type ApplicantResidencyStatusValue =
    ApplicantResidencyStatus[keyof ApplicantResidencyStatus];

type ApplicantResidencyStatusMap = Record<
    ApplicantResidencyStatusValue,
    {
        id: ApplicantResidencyStatusValue;
        labelKey: string;
    }
>;

export const APPLICANT_RESIDENCY_STATUS_MAP: ApplicantResidencyStatusMap = {
    [ApplicantResidencyStatusTypes.PERMANENT]: {
        id: ApplicantResidencyStatusTypes.PERMANENT,
        labelKey: 'permanent',
    },
    [ApplicantResidencyStatusTypes.TEMPORARY]: {
        id: ApplicantResidencyStatusTypes.TEMPORARY,
        labelKey: 'temporary',
    },
    [ApplicantResidencyStatusTypes.NON_RESIDENT]: {
        id: ApplicantResidencyStatusTypes.NON_RESIDENT,
        labelKey: 'nonResident',
    },
    [ApplicantResidencyStatusTypes.UNKNOWN]: {
        id: ApplicantResidencyStatusTypes.UNKNOWN,
        labelKey: 'unknown',
    },
};

export const useApplicantResidencyStatusOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { applicantResidencyStatusTypes },
        },
    } = useTenantSettings();

    return applicantResidencyStatusTypes.map((typeId) => ({
        value: typeId,
        label: i18n._(APPLICANT_RESIDENCY_STATUS_MAP[typeId].labelKey),
    }));
};
