import React, { useMemo } from 'react';

import { Trans } from '@lingui/react';
import { Flex, Spinner } from '@nestoca/ui';
import { Edit } from 'grommet-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { EditableCell } from 'components/editable';
import { CheckboxComponent, Label } from 'components/forms';
import { OTHER_INCOME_FREQUENCY_OPTIONS } from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { getProblemsBySection } from 'store/applications';
import { useEditingRights } from 'store/rights';
import { includedInLoadingState } from 'store/ui';
import { ExistingIncomeOther, IncomeOther } from 'types/applicant';
import { useEditingContext } from 'utils/use-editing-context';
import { useProblems } from 'utils/use-problems';
import { compareForId } from 'utils/validations/comparators';

import { OtherIncomeFieldsCreation } from './other-income-field-creation';
import styles from './other-income.module.scss';

type Props = {
    applicationId: number;
    applicantId: number;
    otherIncome: IncomeOther | ExistingIncomeOther;
    isEditing: boolean;
    isCreating?: boolean;
    editableKey?: string;
    readonly?: boolean;
    onToggleIncluded?: (checked: boolean) => void;
};

export const OtherIncomeFields = ({
    otherIncome,
    applicantId,
    isEditing,
    isCreating = false,
    applicationId,
    editableKey,
    readonly = false,
    onToggleIncluded,
}: Props) => {
    const { setEditingKey } = useEditingContext();
    const { i18n } = useI18n();
    const methods = useFormContext();
    const { showValidationProblems } = useFlags();
    const setIncludedInLoadingState = useSetRecoilState(includedInLoadingState);
    const loadingState = useRecoilValue(includedInLoadingState);
    const sectionProblems = useRecoilValue(
        getProblemsBySection({
            applicationId: applicationId || 0,
            section: ProblemType.incomeOthers,
        })
    );

    const hasEditingRights = useEditingRights();

    const isExistingIncomeOther = (
        income: IncomeOther | ExistingIncomeOther
    ): income is ExistingIncomeOther => {
        return (income as ExistingIncomeOther).id !== undefined;
    };

    useProblems(
        showValidationProblems ? sectionProblems : [],
        applicantId,
        otherIncome
    );

    let type = otherIncome?.type;

    if (methods) {
        const { watch } = methods;

        type = watch('type', otherIncome?.type);
    }

    const isDescriptionRequired = useMemo(
        () => ['INVESTMENT_INCOME', 'INTEREST_INCOME', 'OTHER'].includes(type),
        [type]
    );

    const isDescriptionEditable = useMemo(
        () =>
            [
                'INVESTMENT_INCOME',
                'INTEREST_INCOME',
                'OTHER',
                'EI_INDEMNITY_INCOME',
                'RRIF',
                'RENTAL_INCOME_SURPLUS',
            ].includes(type),
        [type]
    );

    const handleToggleIncluded = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (isExistingIncomeOther(otherIncome)) {
            setIncludedInLoadingState([...loadingState, otherIncome.id]);
            onToggleIncluded?.(event.currentTarget.checked);
        }
    };

    const disableCheckbox =
        isExistingIncomeOther(otherIncome) &&
        !loadingState.includes(otherIncome.id) &&
        loadingState.length > 0;

    return (
        <>
            {isCreating && (
                <OtherIncomeFieldsCreation
                    isEditing={isEditing}
                    applicationId={applicationId}
                    applicantId={applicantId}
                    otherIncome={otherIncome}
                    isDescriptionRequired={isDescriptionRequired}
                    isDescriptionEditable={isDescriptionEditable}
                />
            )}
            {!isCreating &&
                !isEditing &&
                hasEditingRights &&
                isExistingIncomeOther(otherIncome) && (
                    <Label
                        name={`checkbox-${otherIncome.id}`}
                        style={{
                            fontSize: '12px',
                            flexDirection: 'column',
                            display: 'flex',
                            color: '#949599',
                        }}
                    >
                        <Trans id="includeInIncome" />
                        {loadingState.includes(otherIncome.id) ? (
                            <Spinner />
                        ) : (
                            <CheckboxComponent
                                // Will make the checkbox more responsive but I think we should add a loading indicator
                                // defaultChecked={liability?.included}
                                checked={otherIncome?.ratiosIncluded}
                                onChange={handleToggleIncluded}
                                disabled={disableCheckbox}
                                css={{
                                    cursor: disableCheckbox
                                        ? 'auto'
                                        : 'pointer',
                                }}
                            />
                        )}
                    </Label>
                )}
            {!isCreating && isDescriptionEditable ? (
                <EditableCell
                    required={isDescriptionRequired}
                    isEditing={isEditing}
                    name="description"
                    label="description"
                    value={otherIncome?.description}
                    isFieldInvalidCompareFn={
                        isExistingIncomeOther(otherIncome)
                            ? compareForId(otherIncome)
                            : () => false
                    }
                    problemType={ProblemType.incomeOthers}
                />
            ) : (
                !isCreating && <CellData tx="description" value="-" />
            )}
            <EditableCell
                className={styles['frequency-field']}
                required
                isEditing={isEditing}
                name="income.frequency"
                label="frequency"
                fieldType="select"
                options={OTHER_INCOME_FREQUENCY_OPTIONS(i18n)}
                value={otherIncome?.income?.frequency}
                isFieldInvalidCompareFn={
                    isExistingIncomeOther(otherIncome)
                        ? compareForId(otherIncome)
                        : () => false
                }
                problemType={ProblemType.incomeOthers}
            />
            <EditableCell
                className={styles['amount-field']}
                required
                isEditing={isEditing}
                name="income.amount"
                label="amount"
                fieldType="money"
                value={otherIncome?.income?.amount}
                variant="lightGray"
                isFieldInvalidCompareFn={
                    isExistingIncomeOther(otherIncome)
                        ? compareForId(otherIncome)
                        : () => false
                }
                problemType={ProblemType.incomeOthers}
            />

            {!readonly && !isEditing && editableKey && hasEditingRights && (
                <Flex
                    align="center"
                    onClick={() => setEditingKey(editableKey)}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <Edit color="currentColor" size="14px" />
                </Flex>
            )}
        </>
    );
};
