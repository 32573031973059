import React from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { EditableCell } from 'components/editable';
import {
    HourlyCommission,
    NoIncome,
    Pension,
    Salaried,
    SelfEmployed,
} from 'components/employment/employment-type';
import { BORROWER_OCCUPATION } from 'constants/appConstants';
import { ProblemType } from 'constants/problem';
import { useI18n } from 'providers/i18n/use-i18n';
import { getProblemsBySection } from 'store/applications';
import {
    EmploymentIncomeType,
    IncomeEmployment,
    IncomeIncludedFieldName,
} from 'types/applicant';
import { useProblems } from 'utils/use-problems';

import { BaseEmployment } from './base-employment';
import { SelfEmployedStated } from './self-employed-stated';

export type OnToggleIncluded = (
    checked: boolean,
    fieldName: IncomeIncludedFieldName
) => void;

const noOccupationType = ['NONE', 'PENSION'];

type Props = {
    isCreating?: boolean;
    isEditing: boolean;
    applicationId?: number;
    applicantId?: number;
    employment: IncomeEmployment & { totalIncome?: number };
    partialSchema: boolean;
    onToggleIncluded?: OnToggleIncluded;
};

type RequiredProps = Required<Props>;

export type EmploymentTypeSectionProps = {
    incomeTypeWatch: EmploymentIncomeType;
} & Pick<
    RequiredProps,
    | 'isCreating'
    | 'isEditing'
    | 'employment'
    | 'partialSchema'
    | 'onToggleIncluded'
>;

export const EmploymentType = ({
    isCreating,
    isEditing,
    applicationId,
    applicantId,
    employment,
    partialSchema,
    onToggleIncluded,
}: Props) => {
    const { showValidationProblems } = useFlags();
    const { i18n } = useI18n();
    const { watch } = useFormContext() || {};

    let incomeTypeWatch = employment.incomeType;

    if (watch) {
        incomeTypeWatch = watch('incomeType');
    }

    const EMPLOYMENT_TYPE = {
        SALARIED: Salaried,
        HOURLY: HourlyCommission,
        HOURLY_COMMISSIONS: HourlyCommission,
        COMMISSIONS: HourlyCommission,
        SELF_EMPLOYED: SelfEmployed,
        PENSION: Pension,
        NONE: NoIncome,
        ON_CONTRACT: BaseEmployment,
        BONUS: BaseEmployment,
        OVERTIME_AND_PREMIUMS: BaseEmployment,
        SEASONAL: BaseEmployment,
        OTHER: BaseEmployment,
        SELF_EMPLOYED_STATED: SelfEmployedStated,
    };

    const sectionProblems = useRecoilValue(
        getProblemsBySection({
            applicationId: applicationId || 0,
            section: ProblemType.employments,
        })
    );

    useProblems(
        showValidationProblems ? sectionProblems : [],
        applicantId,
        employment
    );

    if (!incomeTypeWatch) return null;

    const EmploymentTypeComponent = EMPLOYMENT_TYPE[incomeTypeWatch];

    return (
        <>
            {!noOccupationType.includes(incomeTypeWatch) && isCreating && (
                <EditableCell
                    required
                    isEditing={isEditing}
                    name="occupation"
                    label="occupation"
                    fieldType="select"
                    menuPlacement="top"
                    options={BORROWER_OCCUPATION(i18n)}
                    value={employment?.occupation}
                />
            )}
            <EmploymentTypeComponent
                isEditing={isEditing}
                isCreating={!!isCreating}
                employment={employment}
                incomeTypeWatch={incomeTypeWatch}
                partialSchema={partialSchema}
                onToggleIncluded={onToggleIncluded}
            />
        </>
    );
};
