import { Flex, Typography } from '@nestoca/ui';

import { AddressEditable } from 'components/editable/address-editable';
import {
    SelfEmployedDetails,
    SelfEmployedIndustryDetails,
    IndustryDetails,
} from 'components/employment/section';
import { useI18n } from 'providers/i18n/use-i18n';

import { IncomeDetailsShort } from '../section/income-details-short';

import { EmploymentTypeSectionProps } from './employment-type';

export const SelfEmployedStated = ({
    isEditing,
    isCreating,
    employment,
    partialSchema,
    onToggleIncluded,
}: EmploymentTypeSectionProps) => {
    const { i18n } = useI18n();

    const { address } = employment?.employer || {};

    return (
        <>
            <SelfEmployedIndustryDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
            />
            <IncomeDetailsShort
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
                onToggleIncluded={onToggleIncluded}
            />
            <IndustryDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
                partialSchema={partialSchema}
            />
            <SelfEmployedDetails
                isCreating={isCreating}
                isEditing={isEditing}
                employment={employment}
                partialSchema={partialSchema}
            />
            <Flex direction="column" gap={4}>
                <Typography weight={7} textColor="var(--color-black)">
                    {i18n._('employerAddress')}
                </Typography>
                <AddressEditable
                    required={!partialSchema}
                    isEditing={isEditing}
                    address={address}
                    namePrefix="employer.address."
                    menuPlacement="bottom"
                    gridGap="var(--spacing-4)"
                    gridTemplateColumns={
                        isCreating
                            ? 'repeat(auto-fit, minmax(var(--size-13), 1fr))'
                            : 'repeat(auto-fill, minmax(var(--size-13), 1fr))'
                    }
                />
            </Flex>
        </>
    );
};
