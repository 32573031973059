import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/react';
import { useTenantFlags } from '@nestoca/multi-tenant';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Box } from 'reflexbox/styled-components';

import { Button } from 'components/button-v2';
import { useConfirm } from 'components/confirm/use-confirm';
import { EditableCell } from 'components/editable';
import { Grid } from 'components/grid/grid';
import { useToasts } from 'components/toast';
import { PRE_APPROVAL_TYPE } from 'constants/appConstants';
import { useTransactionTypeOptions } from 'constants/transaction-types';
import { client as apiClient } from 'libs/api';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import {
    getApplication,
    useRefreshApplicationById,
    useRefreshApplicationMortgage,
} from 'store/applications';
import { useRefreshApplicationDocumentsCounts } from 'store/documents';
import {
    useRefreshDefaultRateFiltersSelector,
    useRefreshDefaultHelocRateFiltersSelector,
} from 'store/filters.selector';
import { useRefreshHelocApplication } from 'store/heloc';
import { useRefreshQualification } from 'store/qualification';
import { ApplicationType, PreApprovalType } from 'types/application';
import yup from 'validations/yup-extended';

export type ChangeApplicationTypeModalProps = {
    applicationId: number;
};

const validationSchema = (i18n: I18n) =>
    yup.object().shape({
        applicationType: yup.string().required(i18n._('error.fieldRequired')),
        preApprovalType: yup.string().when('applicationType', {
            is: (applicationType: ApplicationType) =>
                applicationType === 'PRE_APPROVAL',
            then: (schema) => schema.required(i18n._('error.fieldRequired')),
            otherwise: (schema) => schema.optional(),
        }),
    });

type FormValues = {
    applicationType: ApplicationType;
    preApprovalType: PreApprovalType;
};

export const ChangeApplicationType = ({
    applicationId,
}: ChangeApplicationTypeModalProps) => {
    const { i18n } = useI18n();
    const { addToast } = useToasts();
    const {
        enableRemovingApplicationHelocProduct,
        enableRemovingApplicationMortgageProduct,
    } = useTenantFlags();
    const application = useRecoilValue(getApplication(applicationId));
    const { open: openConfirm } = useConfirm();
    const { close: closeChangeApplicationTypeModal } =
        useModal<ChangeApplicationTypeModalProps>('changeApplicationType');
    const transactionTypeOptions = useTransactionTypeOptions();

    const refreshApplication = useRefreshApplicationById(applicationId);
    const { refresh: refreshQualification } = useRefreshQualification();
    const refreshApplicationDocumentsCounts =
        useRefreshApplicationDocumentsCounts({ applicationId });
    const refreshApplicationMortgage =
        useRefreshApplicationMortgage(applicationId);
    const refreshHeloc = useRefreshHelocApplication(applicationId);

    const refreshDefaultRateFiltersSelector =
        useRefreshDefaultRateFiltersSelector(applicationId);

    const refreshDefaultHelocRateFiltersSelector =
        useRefreshDefaultHelocRateFiltersSelector(applicationId);

    const methods = useForm<FormValues>({
        resolver: yupResolver(validationSchema(i18n)),
    });

    const initialType = application?.type;

    const type = methods?.watch('applicationType', application?.type);

    const onSubmit = async (values: FormValues) => {
        try {
            // BE throws a 500 if we change to the same application type
            if (initialType !== values.applicationType) {
                await apiClient.updateApplicationType(
                    applicationId,
                    values.applicationType
                );
            }

            if (values.applicationType === 'PRE_APPROVAL') {
                await apiClient.patchApplicationPreApprovalType(
                    applicationId,
                    values.preApprovalType
                );
            }

            await refreshApplication();
            await refreshHeloc();
            await refreshApplicationMortgage();
            await refreshQualification(applicationId);
            await refreshApplicationDocumentsCounts();

            refreshDefaultRateFiltersSelector();
            refreshDefaultHelocRateFiltersSelector();

            closeChangeApplicationTypeModal();

            addToast(i18n._('successfullySaved'), {
                appearance: 'success',
            });
        } catch (error) {
            addToast(`Error: ${i18n._({ id: 'failedToSave' })}`, {
                appearance: 'error',
            });
        }
    };

    const handleSubmit = async (values: any) => {
        openConfirm({
            onCancel: undefined,
            onConfirm: async () => onSubmit(values),
            children: (
                <Box fontSize={3}>
                    <Trans
                        id={
                            enableRemovingApplicationHelocProduct &&
                            enableRemovingApplicationMortgageProduct
                                ? 'changeApplication.confirmProductLoss'
                                : 'changeApplication.confirm'
                        }
                    />
                </Box>
            ),
        });
    };

    return (
        <FormProvider {...methods}>
            <Grid
                as="form"
                onSubmit={methods.handleSubmit(handleSubmit)}
                gridGap={10}
                gridTemplateColumns="1fr"
                gridAutoRows="auto"
                marginX={6}
            >
                <EditableCell
                    isEditing
                    name={`applicationType`}
                    value={application.type}
                    fieldType="select"
                    label={i18n._(`changeApplication.type`)}
                    options={transactionTypeOptions}
                    required
                />
                {type === 'PRE_APPROVAL' && (
                    <EditableCell
                        isEditing
                        name={`preApprovalType`}
                        fieldType="select"
                        label={i18n._(`preApprovalType`)}
                        options={PRE_APPROVAL_TYPE(i18n)}
                        value={application?.preApprovalType}
                        required
                    />
                )}
                <Button data-testid="save-button" type="submit" size={'large'}>
                    <Trans id="save" />
                </Button>
            </Grid>
        </FormProvider>
    );
};
