import { useState } from 'react';

import { useTenantSetting } from '@nestoca/multi-tenant';
import {
    Chip,
    Typography,
    Flex,
    Button,
    Box,
    Divider,
    Grid,
    isEmptyArray,
} from '@nestoca/ui';
import intlFormat from 'date-fns/intlFormat';
import * as inflection from 'inflection';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRecoilValue } from 'recoil';

import { Popper } from 'components/popper/popper';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { getApplicantsList, selectedApplication } from 'store/applications';
import { getQualificationState } from 'store/qualification';
import { useEditingRights } from 'store/rights';
import { isEmpty } from 'utils';

import styles from './credit-score.module.scss';

import type { PullCreditModalProps } from 'components/scenarios/components/pull-credit-modal';
import type { Applicant } from 'types/applicant';

export type CreditScoreProps = {
    applicationId: number;
    lightQualification?: boolean;
};

const disableCreditPullStates = ['CLOSED', 'FUNDED', 'EXPIRED'];

export const CreditScore = ({
    applicationId,
    lightQualification = false,
}: CreditScoreProps) => {
    const qualification = useRecoilValue(getQualificationState(applicationId));
    const applicants = useRecoilValue(getApplicantsList(applicationId));

    const { i18n } = useI18n();

    if (isEmpty(qualification)) {
        return null;
    }

    return (
        <Flex
            direction="column"
            align="start"
            gap={3}
            className={styles['credit-score']}
        >
            <Typography size={1} weight={7}>
                {i18n._('creditScoresLabel')}
            </Typography>
            <Divider className={styles['credit-score__divider']} />
            {!isEmptyArray(applicants) &&
                applicants.map((applicant) => (
                    <CreditScoreRow
                        key={`creditRow-${applicant.applicantId}`}
                        applicationId={applicationId}
                        applicant={applicant}
                        lightQualification={lightQualification}
                    />
                ))}
        </Flex>
    );
};

export type CreditScoreRowProps = {
    applicationId: number;
    applicant: Applicant;
    dashboardView?: boolean;
    lightQualification?: boolean;
};

export const CreditScoreRow = ({
    applicationId,
    applicant,
    dashboardView = false,
    lightQualification = false,
}: CreditScoreRowProps) => {
    const { advisorTeamLead } = useFlags();
    const { value: pullCreditEnabled } = useTenantSetting('pullCredit');
    const showPullCreditCta = !dashboardView && pullCreditEnabled;

    const { i18n } = useI18n();
    const hasEditingRights = useEditingRights();
    const { applicationState } = useRecoilValue(selectedApplication);

    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    const { open: openPullCreditModal } =
        useModal<PullCreditModalProps>('pullCredit');

    const creditReportHit =
        applicant?.creditReport?.hit &&
        applicant?.creditReport?.pullType === 'HARD'; // we disable the pull credit only for HARD PULL
    const creditReportExpired = applicant?.creditReport?.expired;

    const creditReportIsHitAndExpired = !(
        creditReportHit && creditReportExpired
    );

    // if hit==false we have to show the button
    const isPullDisabled = creditReportHit
        ? creditReportIsHitAndExpired
        : false;

    const pullDisabled = advisorTeamLead
        ? false
        : applicant?.creditReport && isPullDisabled;

    const pulledBy = applicant?.creditReport?.pulledBy;

    return (
        <>
            <Grid className={styles['credit-score-row']}>
                <Box data-testid="applicantName">
                    <Typography size={1}>
                        {`${applicant.firstName} ${applicant.lastName}`}
                    </Typography>
                </Box>
                <Box data-testid="creditScore">
                    <Typography
                        size={1}
                        weight={7}
                        textColor={
                            applicant?.creditReport?.hit
                                ? 'var(--color-black)'
                                : 'var(--color-primary-a-500)'
                        }
                    >
                        {!applicant?.creditReport?.hit ? (
                            i18n._('noCredit')
                        ) : (
                            <Flex direction="column" align="start" gap={0}>
                                <Flex gap={2}>
                                    {applicant?.creditReport?.score}
                                    <Chip
                                        size="small"
                                        variant="info"
                                        label={inflection.capitalize(
                                            applicant?.creditReport?.pullType.toLowerCase()
                                        )}
                                    />
                                </Flex>
                                {pulledBy !== 'INTERNAL' && (
                                    <Typography
                                        weight={6}
                                        textColor="var(--color-black)"
                                        style={{ fontSize: '12px' }}
                                    >
                                        {i18n._(`pulledBy.${pulledBy}`)}
                                    </Typography>
                                )}
                            </Flex>
                        )}
                    </Typography>
                </Box>
                {showPullCreditCta && (
                    <Flex
                        data-testid="pullCredit"
                        className={styles['credit-score-row__pull-credit-cta']}
                    >
                        {/* Wrap button with a Flex component because Browsers do not fire pointer events on disabled elements */}
                        {/* when disable point event need to be disable on the child to work */}
                        <Flex
                            ref={setReferenceElement}
                            onMouseOver={() => {
                                if (!pullDisabled) {
                                    setIsPopperOpen(true);
                                }
                            }}
                            onMouseLeave={() => {
                                if (!pullDisabled) {
                                    setIsPopperOpen(false);
                                }
                            }}
                        >
                            <Button
                                data-testid="buttonPullCredit"
                                variant="alternative"
                                size="small"
                                css={{
                                    pointerEvents: pullDisabled
                                        ? 'none'
                                        : undefined,
                                }}
                                onClick={() => {
                                    openPullCreditModal({
                                        applicationId,
                                        applicant,
                                        lightQualification,
                                    });
                                }}
                                disabled={
                                    pullDisabled ||
                                    !hasEditingRights ||
                                    disableCreditPullStates.includes(
                                        applicationState
                                    )
                                }
                            >
                                {i18n._('pullCredit')}
                            </Button>
                        </Flex>
                        {applicant?.creditReport?.created && (
                            <Popper
                                isOpen={isPopperOpen}
                                referenceElement={referenceElement}
                            >
                                {i18n._('creditPulled', {
                                    date: intlFormat(
                                        new Date(
                                            applicant?.creditReport?.created
                                        ),
                                        {
                                            year: 'numeric',
                                            month: 'long',
                                            day: '2-digit',
                                        },
                                        { locale: i18n.locale }
                                    ),
                                })}
                            </Popper>
                        )}
                    </Flex>
                )}
            </Grid>
            <Divider className={styles['credit-score__divider']} />
        </>
    );
};
