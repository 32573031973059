import { useTenantSettings } from '@nestoca/multi-tenant';
import { TransactionTypes } from '@nestoca/multi-tenant';

import { useI18n } from 'providers/i18n/use-i18n';

type Transaction = typeof TransactionTypes;

type TransactionValue = Transaction[keyof Transaction];

type TransactionTypesMap = Record<
    TransactionValue,
    {
        id: TransactionValue;
        labelKey: string;
    }
>;

export const TRANSACTION_TYPE_MAP: TransactionTypesMap = {
    [TransactionTypes.ASSUMPTION]: {
        id: TransactionTypes.ASSUMPTION,
        labelKey: `type.ASSUMPTION`,
    },
    [TransactionTypes.BRIDGE]: {
        id: TransactionTypes.BRIDGE,
        labelKey: `type.BRIDGE`,
    },
    [TransactionTypes.CHANGE_OF_COVENANT]: {
        id: TransactionTypes.CHANGE_OF_COVENANT,
        labelKey: `type.CHANGE_OF_COVENANT`,
    },
    [TransactionTypes.CONSTRUCTION_LOAN]: {
        id: TransactionTypes.CONSTRUCTION_LOAN,
        labelKey: `type.CONSTRUCTION_LOAN`,
    },
    [TransactionTypes.NEW]: {
        id: TransactionTypes.NEW,
        labelKey: `type.NEW`,
    },
    [TransactionTypes.PORT]: {
        id: TransactionTypes.PORT,
        labelKey: `type.PORT`,
    },
    [TransactionTypes.PRE_APPROVAL]: {
        id: TransactionTypes.PRE_APPROVAL,
        labelKey: `type.PRE_APPROVAL`,
    },
    [TransactionTypes.PURCHASE_WITH_IMPROVEMENT]: {
        id: TransactionTypes.PURCHASE_WITH_IMPROVEMENT,
        labelKey: `type.PURCHASE_WITH_IMPROVEMENT`,
    },
    [TransactionTypes.READVANCE]: {
        id: TransactionTypes.READVANCE,
        labelKey: `type.READVANCE`,
    },
    [TransactionTypes.REFINANCE]: {
        id: TransactionTypes.REFINANCE,
        labelKey: `type.REFINANCE`,
    },
    [TransactionTypes.RENEWAL]: {
        id: TransactionTypes.RENEWAL,
        labelKey: `type.RENEWAL`,
    },
    [TransactionTypes.TRANSFER]: {
        id: TransactionTypes.TRANSFER,
        labelKey: `type.TRANSFER`,
    },
};

export const useTransactionTypeOptions = () => {
    const { i18n } = useI18n();

    const {
        typesOverride: {
            settings: { transactionTypes },
        },
    } = useTenantSettings();

    return transactionTypes.map((typeId) => ({
        value: typeId,
        label: i18n._(TRANSACTION_TYPE_MAP[typeId].labelKey),
    }));
};
