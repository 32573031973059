import React from 'react';

import { Box, Grid } from '@nestoca/ui';
import { useRouter } from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import { EMPLOYMENT_TYPE_LABEL } from 'constants/appConstants';
import { INCOME_MAP } from 'constants/icnome';
import { MARITAL_STATUS } from 'constants/labels';
import { useI18n } from 'providers/i18n/use-i18n';
import { getDashboardApplicantSelector } from 'store/applications';
import { sectionSelectedState } from 'store/qualification';
import { formatMoney } from 'utils';

import styles from './applicant-card.module.scss';

import type { Applicant as ApplicantType } from 'types/applicant';

type Props = {
    applicant: ApplicantType;
};

export const ApplicantInfo = ({ applicant }: Props) => {
    const router = useRouter();
    const { i18n } = useI18n();
    const { applicantId, firstName, lastName, maritalStatus, income } =
        applicant;

    const { applicationId, totalIncome, isMainApplicant } = useRecoilValue(
        getDashboardApplicantSelector({ applicantId })
    );

    const setSectionSelected = useSetRecoilState(
        sectionSelectedState({ applicationId })
    );

    const currentEmployment = income.employments.find(
        (employment) => employment.isCurrent
    );

    return (
        <Box>
            <CellData
                tx={
                    isMainApplicant
                        ? i18n._('mainApplicant')
                        : i18n._('coApplicant')
                }
                value={`${firstName} ${lastName}`}
            />
            <Grid className={styles.applicant_info_grid}>
                <CellData
                    tx="Income Type"
                    value={i18n._(
                        INCOME_MAP[currentEmployment?.incomeType]?.labelKey ||
                            ''
                    )}
                />
                <CellData
                    tx="employmentType"
                    value={
                        EMPLOYMENT_TYPE_LABEL(i18n)[
                            currentEmployment?.employmentType
                        ]
                    }
                />
                <CellData
                    tx="maritalStatus"
                    value={MARITAL_STATUS(i18n)[maritalStatus]}
                />
                <CellData
                    css={{ cursor: 'pointer' }}
                    tx="dashboardHeader.totalIncome"
                    value={formatMoney(totalIncome)}
                    onClick={() => {
                        setSectionSelected('employments');
                        router.push(`/applications/${applicationId}`);
                    }}
                    variant="link"
                />
            </Grid>
        </Box>
    );
};
